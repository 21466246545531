<template>
  <modal-wrapper
    :id="id"
    hide-footer
    hide-header
    size="md"
  >
    <template #title>
      Informazioni aggiuntive
    </template>
    <template #body>
      <div v-if="isAborted">
        <p>
          Ritira la segnalazione se sono emersi fatti o errori che ne invalidano il contenuto
          (ad esempio, qualora si sia chiarito il malinteso che ha generato un’erronea segnalazione,
          oppure qualora sia emerso un tuo errore materiale nella compilazione della segnalazione
          stessa). Nella finestra che si aprirà, potrai spiegare la ragione per cui intendi chiudere
          la segnalazione. La segnalazione non sarà cancellata dal database, ma non comparirà più
          nel profilo del soggetto segnalato.
        </p>
      </div>
      <div v-if="isClose">
        <p>
          Puoi chiudere la segnalazione effettuata nel caso in cui, successivamente alla stessa,
          il soggetto segnalato abbia provveduto ad adempiere rispetto alla scorrettezza/violazione
          segnalata (ad esempio, qualora la controparte abbia saldato il mancato pagamento oggetto
          di segnalazione). Nella finestra che si aprirà, potrai spiegare la ragione per cui
          intendi chiudere la segnalazione. La segnalazione non sarà cancellata dal database, ma
          l’informazione fornita comparirà nel profilo del soggetto segnalato e contribuirà a
          modificarne la valutazione.
        </p>
      </div>
      <div v-if="isLitigation">
        <p>
          Puoi rispondere alla segnalazione contestandone il contenuto
        </p>
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ModalFeedback',
  components: {
    ModalWrapper,
  },
  props: {
    id: String,
    onModalHide: Function,
    state: String,
  },
  computed: {
    isAborted() {
      return this.state === 'ABORTED';
    },
    isClose() {
      return this.state === 'USER_CLOSED';
    },
    isLitigation() {
      return this.state === 'LITIGATION';
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.id);
    },
  },
};
</script>

<style scoped>

</style>
